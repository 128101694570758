// using module.exports here so gatsby-config can read it

const siteUrlDefault = 'https://www.remoteteam.com';

const config = {
  title: 'Remote Team',
  titleTemplate: '%s',
  siteUrl: process.env.SITE_URL ? process.env.SITE_URL : siteUrlDefault,
  lealTitle: 'Remote Team Inc.',
  description:
    'US Payroll, PEO &amp; EOR Services, tax compliances, time-off management, and many more. All designed for Remote First teams.',
  url: process.env.SITE_URL ? process.env.SITE_URL : siteUrlDefault,
  image:
    'https://res.cloudinary.com/hebu10lmu/image/upload/c_scale,f_auto,q_auto,w_350/v1597065935/www/forestry/remote-team-blue-logo_mhisgu.svg', // Path to your image you placed in the 'static' folder
  logoBlue:
    'https://res.cloudinary.com/hebu10lmu/image/upload/c_scale,q_auto,h_110/v1597065935/www/forestry/remote-team-blue-logo_mhisgu.svg',
  postThumbnail:
    'https://res.cloudinary.com/hebu10lmu/image/upload/c_scale,f_auto,q_auto/v1597145752/www/forestry/remoteteam-com-banner_kvaz7i.png',
  twitterUsername: '@remoteteamcom',
  blogDesktopBanner:
    '//cdn.bannersnack.com/banners/bdzm2unx4/embed/index.html?userId=42465324&t=1610719219',
  blogMobileBanner:
    '//cdn.bannersnack.com/banners/bu9sgifn7/embed/index.html?userId=42465324&t=1610571847',
  airtableTables: [
    'Legal pages',
    'Independent contractors',
    'State payroll tax registration pages',
    // 'Freelancer',
    // 'Country Pages',
    // 'App partners',
    // 'Wfh',
    // 'Send money',
    // 'State pages',
    // 'Tool review',
    // 'Invoice',
    // 'Contracts 1099',
    // 'App compare',
  ],
  mainColor: '#2d46c1',
  // those fields are required for the page generation process.
  airtableSpecialFields: ['slug', 'title', 'description', 'headline', 'published'],
};

module.exports = config;
