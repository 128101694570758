module.exports = [{
      plugin: require('../plugins/gatsby-plugin-top-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://164053d8effe4ff0b9024af512ad0920@o565617.ingest.sentry.io/5707317","sampleRate":0.7},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Remote Team","short_name":"Remote Team","icon":"static/favicon/remoteteam.png","start_url":"/","background_color":"#fefefe","theme_color":"#fefefe","display":"standalone","cache_busting_mode":"none","legacy":true,"theme_color_in_head":true,"crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    }]
