import * as React from 'react';
import Link from '../Link/Link';
import Micro from '../../layouts/Micro';
import packageJson from '../../../package.json';

import './footer.scss';

const Footer = () => {
  const buildDate = Date.now();

  return (
    <Micro>
      <footer className="footer micro">
        <div className="container">
          <div className="grid-5">
            <div className="align-vertical">
              <div>
                <img
                  loading="lazy"
                  height="60"
                  src="https://res.cloudinary.com/hebu10lmu/image/upload/c_scale,q_auto,w_110/v1597236265/www/logo-white-remoteteam_sky1mo.svg"
                  alt={`Remote Operation System - Remote Team Inc. v${packageJson.version} build time: ${buildDate}`}
                />

                <div className="padding-top-1rem card-neumorphic-dark padding-1rem">
                  <h6>Remote Operation System</h6>
                  <p>
                    RemoteTeam.com is an all-in-one Remote OS that powers remote teams operations.
                  </p>
                </div>

                <p className="padding-1rem">
                  © Remote Team Inc. <strong>{new Date().getFullYear()}</strong> All rights
                  reserved.
                </p>
              </div>
            </div>

            <div>
              <h5 className="text-white fw-600">Site</h5>
              <ul className="list-unstyled text-small">
                <li>
                  <Link className="text-white hover-text-yellow" href="/policies/terms">
                    Terms of Service
                  </Link>
                </li>
                <li>
                  <Link className="text-white hover-text-yellow" href="/policies/privacy">
                    Privacy policy
                  </Link>
                </li>
                <li>
                  <Link
                    className="text-white hover-text-yellow"
                    href="/policies/do-not-sell-my-data"
                  >
                    Do Not Sell My Personal Information
                  </Link>
                </li>
                {/* <li>
                  <Link className="text-white hover-text-yellow" href="/sitemap">
                    Sitemap
                  </Link>
                </li> */}
                <li>
                  <Link className="text-white hover-text-yellow" href="/blog/tags">
                    Tag Cloud
                  </Link>
                </li>
                <li>
                  <Link
                    className="text-white hover-text-yellow"
                    href="https://status.remoteteam.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Status
                  </Link>
                </li>
              </ul>
            </div>

            <div className="list">
              <h5 className="title">About</h5>
              <ul className="list-nav">
                <li>
                  <Link className="text-white hover-text-yellow" href="/about-us">
                    About Us
                  </Link>
                </li>
                <li>
                  <Link className="text-white hover-text-yellow" href="/pricing">
                    Pricing
                  </Link>
                </li>
                <li>
                  <Link className="text-white hover-text-yellow" href="/platform">
                    Platform
                  </Link>
                </li>
                <li>
                  <Link className="text-white hover-text-yellow" href="/security">
                    Security
                  </Link>
                </li>
                <li>
                  <Link className="text-white hover-text-yellow" href="/press">
                    Press Kit
                  </Link>
                </li>
              </ul>
            </div>

            <div className="list">
              <h5 className="title">HR Tools</h5>
              <ul className="list-nav">
                <li>
                  <Link className="text-white hover-text-yellow" href="/digital-signature">
                    Online Signature
                  </Link>
                </li>
                <li>
                  <Link className="text-white hover-text-yellow" href="/check-in-app">
                    Check-in App
                  </Link>
                </li>
                <li>
                  <Link className="text-white hover-text-yellow" href="/reimbursement">
                    Reimbursement
                  </Link>
                </li>
                <li>
                  <Link className="text-white hover-text-yellow" href="/team-app">
                    Team App
                  </Link>
                </li>
                <li>
                  <Link className="text-white hover-text-yellow" href="/peo-eor-global-payroll">
                    Payroll Management
                  </Link>
                </li>
                <li>
                  <Link className="text-white hover-text-yellow" href="/time-off">
                    Time off and holidays
                  </Link>
                </li>
              </ul>
            </div>

            <div className="list">
              <h5 className="title">Independent Contractors</h5>
              <ul className="list-nav">
                <li>
                  <Link className="text-white hover-text-yellow" href="/money-transfers">
                    Payments & Transfers
                  </Link>
                </li>
                <li>
                  <Link className="text-white hover-text-yellow" href="/tax-compliance">
                    Tax Compliance & Forms
                  </Link>
                </li>
                <li>
                  <Link className="text-white hover-text-yellow" href="/invoice">
                    {' '}
                    Invoices{' '}
                  </Link>
                </li>
                <li>
                  <Link className="text-white hover-text-yellow" href="/time-tracking">
                    Time Tracking
                  </Link>
                </li>
                <li>
                  <Link className="text-white hover-text-yellow" href="/legal-partners">
                    Legal Partners
                  </Link>
                </li>
              </ul>
            </div>

            {/* <div>
              <h5 className="title">Guides</h5>

              <ul className="list-nav">
                <li>
                  <Link
                    className="text-white hover-text-yellow"
                    href="/independent-contractor-agreements"
                  >
                    Independent Contractor & Payments
                  </Link>
                </li>
                <li>
                  <Link className="text-white hover-text-yellow" href="/hr-tools">
                    HRIS & HR Apps
                  </Link>
                </li>
                <li>
                  <Link className="text-white hover-text-yellow" href="/app-marketplace">
                    App Store for Remote Teams
                  </Link>
                </li>

                <li>
                  <Link className="text-white hover-text-yellow" href="/what-is-a-peo">
                    What Is A PEO?
                  </Link>
                </li>
                <li>
                  <Link
                    className="text-white hover-text-yellow"
                    href="/independent-contractor-full-guide"
                  >
                    Independent Contractors Guide
                  </Link>
                </li>
              </ul>
            </div> */}

            {/* <div>
              <h5 className="title">Send Money</h5>
              <ul className="list-nav">
                <li>
                  <Link className="text-white hover-text-yellow" href="/send-money-to-russia">
                    Pay Your Remote Team In Russia
                  </Link>
                </li>
                <li>
                  <Link className="text-white hover-text-yellow" href="/send-money-to-philippines">
                    Pay Your Remote Team In Philippines
                  </Link>
                </li>
                <li>
                  <Link className="text-white hover-text-yellow" href="/send-money-to-india">
                    Pay Your Remote Team In India
                  </Link>
                </li>
                <li>
                  <Link className="text-white hover-text-yellow" href="/send-money-to-mexico">
                    Pay Your Remote Team In Mexico
                  </Link>
                </li>
                <li>
                  <Link className="text-yellow" href="/money-transfer">
                    <strong>More+</strong>
                  </Link>
                </li>
              </ul>
            </div> */}
          </div>

          {/* <hr /> */}

          <div className="grid-3 padding-top-1rem flex flex-middle ">
            <div>
              <p>
                <Link
                  href="https://gdpr-rep.eu/q/13008112"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    className="pr-2 badge"
                    loading="lazy"
                    src="https://res.cloudinary.com/hebu10lmu/image/upload/q_auto,w_240/v1607026206/www/homepage/13008112_kitarm.png"
                    alt="GDPR-Rep.eu certificate of Art 27 representation Remote Team"
                  />
                </Link>
                <Link
                  href="https://gdpr-rep.eu/verify/13008112"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    className="pr-2 badge"
                    loading="lazy"
                    src="https://res.cloudinary.com/hebu10lmu/image/upload/q_auto,w_240/v1613730882/www/getwebcertificate_fei5dm.png"
                    alt="PrighterUKRep certificate of Art 27 representation"
                  />
                </Link>
                <Link
                  href="https://www.privacyshield.gov/participant?id=a2zt0000000CqfXAAS&status=Active"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    className="pr-2 badge"
                    loading="lazy"
                    src="https://res.cloudinary.com/hebu10lmu/image/upload/q_auto,w_240/v1596672297/www/icons/privacy-shield-framework-remoteteam-com_ntigf5.png"
                    alt="Privacy Shield badge for Remote Team"
                  />
                </Link>
              </p>
            </div>

            <div className="text-center">
              <Link
                rel="noopener noreferrer"
                target="_blank"
                href="https://twitter.com/remoteteamcom"
              >
                <img
                  src="https://res.cloudinary.com/hebu10lmu/image/upload/c_scale,q_auto/v1596672684/www/icons/twitter_r1qbd6.svg"
                  alt="Remote Team Inc. Twitter Official Social Media Account"
                />
              </Link>

              <Link
                rel="noopener noreferrer"
                target="_blank"
                href="https://www.linkedin.com/company/remoteteam"
              >
                <img
                  src="https://res.cloudinary.com/hebu10lmu/image/upload/c_scale,q_auto/v1596672067/www/icons/linkedin_sprcw8.svg"
                  alt="Remote Team Inc. Linkedin Official Social Media Account"
                />
              </Link>

              <Link
                rel="noopener noreferrer"
                target="_blank"
                href="https://stackshare.io/companies/remoteteam-inc"
              >
                <img
                  src="https://res.cloudinary.com/hebu10lmu/image/upload/c_scale/v1596672068/www/icons/stack_fccajj.svg"
                  alt="Remote Team Inc. StackShare Official Social Media Account"
                />
              </Link>

              <Link rel="noopener noreferrer" target="_blank" href="/rss.xml">
                <img
                  src="https://res.cloudinary.com/hebu10lmu/images/v1613476616/www/blog/rt-rss-feed-icon/rt-rss-feed-icon.svg"
                  alt="RemoteTeam Blog RSS Feed"
                />
              </Link>
            </div>

            <div className="text-right">
              Designed in{' '}
              <span role="img" aria-label="Sun">
                ☀️
              </span>{' '}
              California &amp; Built Globally With{' '}
              <span role="img" aria-label="Heart">
                ❤️
              </span>
            </div>
          </div>
        </div>
      </footer>
    </Micro>
  );
};

export default Footer;
