/* eslint-disable no-script-url */
import React, { useEffect, useState } from 'react';
import { TextAlignJustified, Cross, ChevronDown, ChevronUp, ChevronRight } from 'akar-icons';

import Link from '../Link/Link';
import Micro from '../../layouts/Micro';
import InvoiceGenerator from '../../../static/img/menu-icons/invoice-generator.inline.svg';
import W8Form from '../../../static/img/menu-icons/w8-form.inline.svg';
import AppStore from '../../../static/img/menu-icons/app-store.inline.svg';
import Pricing from '../../../static/img/menu-icons/pricing.inline.svg';
import AboutUs from '../../../static/img/menu-icons/about-us.inline.svg';
import ZoomVirtualBackground from '../../../static/img/menu-icons/zoom-virtual-background.inline.svg';
import Blog from '../../../static/img/menu-icons/blog.inline.svg';
import CountrySides from '../../../static/img/menu-icons/country-sides.inline.svg';

import config from '../../config';

const { logoBlue } = config;

function useWindowSize() {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowSize;
}

const Menu = () => {
  const [activeDropdown, setActiveDropdown] = useState('none');
  const [hamburgerOpen, setHamburgerOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const size = useWindowSize();

  useEffect(() => {
    if (size.width > 1333) {
      setIsMobile(false);
    } else {
      setIsMobile(true);
    }
  }, [size.width]);

  const setDropdown = (activeDropdownValue) => {
    setActiveDropdown(activeDropdownValue);
  };

  const setHamburgerStatus = (hamburgerStatus) => {
    setHamburgerOpen(hamburgerStatus);
  };

  const handleLinkClick = () => {
    setDropdown('remote');
    if (isMobile) {
      setHamburgerStatus(false);
    }
    document.body.style.overflow = 'auto';
  };

  return (
    <Micro>
      <header className="remote-header">
        <div className="menu-container">
          <div className="navigation">
            <figure className="logo">
              <Link
                onClick={handleLinkClick}
                href="/"
                title="Remote Team - Remote Operation System"
              >
                <img loading="lazy" src={logoBlue} alt="RemoteTeam" />
              </Link>
            </figure>

            <menu className="menu" role="navigation">
              <Hamburger
                className="responsive-toggle"
                setHamburgerStatus={setHamburgerStatus}
                hamburgerOpen={hamburgerOpen}
              >
                <ul className="main-menu">
                  <li>
                    <DropDown
                      title="Independent Contractors"
                      activeDropdown={activeDropdown}
                      setDropdown={setDropdown}
                      isMobile={isMobile}
                    >
                      <div className="menu-content">
                        <div className="menu-container">
                          <div className="three-column-grid-container">
                            <div className="main-area menu-card border-radius-20 bg-purple">
                              <section className="grid-1-1 box-padding">
                                <div className="hide-on-mobile w-75">
                                  <Link
                                    onClick={handleLinkClick}
                                    href="/independent-contractor-agreements"
                                  >
                                    <section className="outer">
                                      <h3 className="inner">
                                        <strong>Independent Contractors</strong>
                                      </h3>
                                      <hr className="mt-2rem inner" />
                                      <p className="mt-2rem mute inner">
                                        Working with global contractors is easy with RemoteTeam
                                      </p>
                                      <p className="mt-3rem inner">
                                        <strong>Learn more</strong>
                                        <ChevronRight
                                          strokeWidth={4}
                                          size={12}
                                          style={{
                                            marginLeft: '0.3rem',
                                            marginBottom: '-0.1rem',
                                            display: 'inline',
                                          }}
                                        />
                                      </p>
                                    </section>
                                  </Link>
                                </div>
                                <div className="grid-6-container">
                                  <div className="margin-bottom">
                                    <Link onClick={handleLinkClick} href="/send-money">
                                      <h4>
                                        <strong>Payment &amp; Transfer</strong>
                                      </h4>
                                      <p className="mute margin-0">Fast transfers and lower fees</p>
                                    </Link>
                                  </div>
                                  {/* <div className="margin-bottom">
                                    <Link onClick={handleLinkClick} href="/legal-partners">
                                      <h4>
                                        <strong>Legal Partners</strong>
                                      </h4>
                                      <p className="mute margin-0">Consult with local experts</p>
                                    </Link>
                                  </div> */}
                                  <div className="margin-bottom">
                                    <Link
                                      onClick={handleLinkClick}
                                      href="/independent-contractor-agreements"
                                    >
                                      <h4>
                                        <strong>Contractor Agreements</strong>
                                      </h4>
                                      <p className="mute margin-0">Locally compliant contracts</p>
                                    </Link>
                                  </div>
                                  <div>
                                    <Link onClick={handleLinkClick} href="/invoice">
                                      <h4>
                                        <strong>Invoices</strong>
                                      </h4>
                                      <p className="mute margin-0">
                                        Generate professional invoices
                                      </p>
                                    </Link>
                                  </div>
                                  <div>
                                    <Link onClick={handleLinkClick} href="/time-tracking">
                                      <h4>
                                        <strong>Time Tracking</strong>
                                      </h4>
                                      <p className="mute margin-0">
                                        Sync productivity with payments
                                      </p>
                                    </Link>
                                  </div>
                                  <div>
                                    <Link onClick={handleLinkClick} href="/tax-compliance">
                                      <h4>
                                        <strong>Tax Compliance &amp; Forms</strong>
                                      </h4>
                                      <p className="mute margin-0">W8, W9, 1099 and more</p>
                                    </Link>
                                  </div>
                                </div>
                              </section>
                            </div>
                            <div className="second-area menu-card border-radius-20 bg-green height-120px">
                              <Link onClick={handleLinkClick} href="#remote">
                                <section className="grid-3-2 align-vertical">
                                  <div className="p-1rem">
                                    <h3>Invoice Generator</h3>
                                    <p className="mute margin-0">
                                      Create and send professional invoices in minutes (Coming Soon)
                                    </p>
                                  </div>
                                  <figure className="hide-on-mobile p-1rem">
                                    <InvoiceGenerator
                                      style={{
                                        height: '127px',
                                        marginBottom: '-20px',
                                      }}
                                    />
                                  </figure>
                                </section>
                              </Link>
                            </div>
                            <div className="third-area menu-card border-radius-20 bg-red height-120px">
                              <Link onClick={handleLinkClick} href="/w8">
                                <section className="grid-3-2 align-vertical">
                                  <div className="p-1rem">
                                    <h3>
                                      W8 Forms <br />
                                    </h3>
                                    <p className="mute margin-0">
                                      Manage and send your team’s W-8BEN and W-8BEN-E forms
                                    </p>
                                  </div>
                                  <figure className="hide-on-mobile p-1rem">
                                    <W8Form
                                      style={{
                                        height: '127px',
                                        marginBottom: '-20px',
                                      }}
                                    />
                                  </figure>
                                </section>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </DropDown>
                  </li>

                  <li>
                    <span>
                      <Link className="menu-link" href="/global-payroll">
                        Global Payroll
                      </Link>
                    </span>
                  </li>

                  <li>
                    <DropDown
                      title="HR Tools"
                      activeDropdown={activeDropdown}
                      setDropdown={setDropdown}
                      isMobile={isMobile}
                    >
                      <div className="menu-content">
                        <div className="menu-container">
                          <div className="three-column-grid-container">
                            <div className="main-area menu-card border-radius-20 bg-green height-120px">
                              <section className="grid-1-1 box-padding">
                                <div className="hide-on-mobile w-75">
                                  <Link onClick={handleLinkClick} href="/hr-tools">
                                    <section className="outer">
                                      <h3 className="inner">
                                        <strong>HR Tools</strong>
                                      </h3>
                                      <hr className="mt-2rem inner" />
                                      <p className="mt-2rem mute inner">
                                        Customize your dashboard with features built by RemoteTeam
                                      </p>
                                      <p className="mt-3rem inner">
                                        <strong>Learn more</strong>
                                        <ChevronRight
                                          strokeWidth={4}
                                          size={12}
                                          style={{
                                            marginLeft: '0.3rem',
                                            marginBottom: '-0.1rem',
                                            display: 'inline',
                                          }}
                                        />
                                      </p>
                                    </section>
                                  </Link>
                                </div>
                                <div className="grid-6-container">
                                  <div>
                                    <Link onClick={handleLinkClick} href="/check-in-app">
                                      <h4>
                                        <strong>Check-In App</strong>
                                      </h4>
                                      <p className="mute margin-0">Track working hours</p>
                                    </Link>
                                  </div>
                                  <div>
                                    <Link onClick={handleLinkClick} href="/time-off-manager">
                                      <h4>
                                        <strong>Time Off &amp; Holidays</strong>
                                      </h4>
                                      <p className="mute margin-0">Manage policies and requests</p>
                                    </Link>
                                  </div>
                                  <div className="margin-bottom">
                                    <Link onClick={handleLinkClick} href="/team-app">
                                      <h4>
                                        <strong>Team App</strong>
                                      </h4>
                                      <p className="mute margin-0">Visualize team details</p>
                                    </Link>
                                  </div>
                                  <div className="margin-bottom">
                                    <Link onClick={handleLinkClick} href="/reimbursement">
                                      <h4>
                                        <strong>Reimbursement</strong>
                                      </h4>
                                      <p className="mute margin-0">Fast transfers and lower fees</p>
                                    </Link>
                                  </div>
                                  <div className="margin-bottom">
                                    <Link onClick={handleLinkClick} href="/digital-signature">
                                      <h4>
                                        <strong>Digital Signature</strong>
                                      </h4>
                                      <p className="mute margin-0">Create and sign documents</p>
                                    </Link>
                                  </div>
                                  <div className="margin-bottom">
                                    <Link onClick={handleLinkClick} href="/global-payroll">
                                      <h4>
                                        <strong>Payroll Management</strong>
                                      </h4>
                                      <p className="mute margin-0">
                                        Manage salary, incentives, benefits
                                      </p>
                                    </Link>
                                  </div>
                                </div>
                              </section>
                            </div>
                            <div className="second-area menu-card border-radius-20 bg-red height-120px">
                              <Link onClick={handleLinkClick} href="/app-marketplace">
                                <section className="grid-3-2 align-vertical">
                                  <div className="p-1rem">
                                    <h3>App Store</h3>
                                    <p className="mute margin-0">
                                      Find and manage the apps you need to run a successful remote
                                      team
                                    </p>
                                  </div>
                                  <figure className="hide-on-mobile p-1rem">
                                    <AppStore
                                      style={{
                                        height: '127px',
                                        marginBottom: '-20px',
                                      }}
                                    />
                                  </figure>
                                </section>
                              </Link>
                            </div>
                            <div className="third-area menu-card border-radius-20 bg-purple height-120px">
                              <Link onClick={handleLinkClick} href="/pricing">
                                <div className="grid-3-2 align-vertical">
                                  <div className="p-1rem">
                                    <h3>Pricing</h3>
                                    <p className="mute margin-0">
                                      Explore our simple, transparent pricing
                                    </p>
                                  </div>
                                  <figure className="hide-on-mobile p-1rem">
                                    <Pricing
                                      style={{
                                        height: '127px',
                                        marginBottom: '-20px',
                                      }}
                                    />
                                  </figure>
                                </div>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </DropDown>
                  </li>

                  <li>
                    <span>
                      <Link className="menu-link" href="/app-marketplace">
                        App Store
                      </Link>
                    </span>
                  </li>

                  <li>
                    <span>
                      <Link className="menu-link" href="/pricing">
                        Pricing
                      </Link>
                    </span>
                  </li>

                  {/* <li>
                    <DropDown
                      title="Resources"
                      activeDropdown={activeDropdown}
                      setDropdown={setDropdown}
                      isMobile={isMobile}
                    >
                      <div className="menu-content">
                        <div className="menu-container">
                          <div className="five-column-grid-container">
                            <div className="first-area menu-card border-radius-20 bg-yellow height-120px hide-on-mobile">
                              <Link onClick={handleLinkClick} href="/blog">
                                <section className="p-2rem w-75 outer">
                                  <h3 className="inner" style={{ paddingTop: '1rem' }}>
                                    <strong>Resources</strong>
                                  </h3>
                                  <hr className="mt-2rem inner" />
                                  <p className="mt-2rem mute inner">RemoteTeam is here to help</p>
                                  <p className="mt-3rem inner">
                                    <strong>Learn more</strong>
                                    <ChevronRight
                                      strokeWidth={4}
                                      size={12}
                                      style={{
                                        marginLeft: '0.3rem',
                                        marginBottom: '-0.1rem',
                                        display: 'inline',
                                      }}
                                    />
                                  </p>
                                </section>
                              </Link>
                            </div>
                            <div className="second-area menu-card border-radius-20 bg-purple height-120px">
                              <Link onClick={handleLinkClick} href="/about-us">
                                <section className="grid-3-2 menu-card align-vertical">
                                  <div className="w-100 pl-1rem">
                                    <h3>
                                      <strong>About Us</strong>
                                    </h3>
                                    <p className="mute margin-0">Meet our global team</p>
                                  </div>
                                  <figure className="hide-on-mobile p-1rem">
                                    <AboutUs
                                      style={{
                                        height: '127px',
                                        marginBottom: '-20px',
                                      }}
                                    />
                                  </figure>
                                </section>
                              </Link>
                            </div>
                            <div className="third-area menu-card border-radius-20 bg-green height-120px">
                              <Link onClick={handleLinkClick} href="/zoom-virtual-backgrounds">
                                <section className="grid-3-2 menu-card align-vertical">
                                  <div className="w-100 pl-1rem">
                                    <h3>
                                      <strong>Zoom Virtual Backgrounds</strong>
                                    </h3>
                                    <p className="mute margin-0">Make meetings fun</p>
                                  </div>
                                  <figure className="hide-on-mobile p-1rem">
                                    <ZoomVirtualBackground
                                      style={{
                                        height: '127px',
                                        marginBottom: '-20px',
                                      }}
                                    />
                                  </figure>
                                </section>
                              </Link>
                            </div>
                            <div className="fifth-area menu-card border-radius-20 bg-orange height-120px">
                              <Link onClick={handleLinkClick} href="/blog">
                                <section className="grid-3-2 menu-card align-vertical">
                                  <div className="w-100 pl-1rem">
                                    <h3>
                                      <strong>Blog</strong>
                                    </h3>
                                    <p className="mute margin-0">
                                      Read trending remote work articles
                                    </p>
                                  </div>
                                  <figure className="hide-on-mobile p-1rem">
                                    <Blog
                                      style={{
                                        height: '127px',
                                        marginBottom: '-20px',
                                      }}
                                    />
                                  </figure>
                                </section>
                              </Link>
                            </div>
                            <div className="sixth-area menu-card border-radius-20 bg-red height-120px">
                              <Link onClick={handleLinkClick} href="/send-money">
                                <section className="grid-3-2 menu-card align-vertical">
                                  <div className="w-100 pl-1rem">
                                    <h3>
                                      <strong>Country Guides</strong>
                                    </h3>
                                    <p className="mute margin-0">Get help with local hiring</p>
                                  </div>
                                  <figure className="hide-on-mobile p-1rem">
                                    <CountrySides
                                      style={{
                                        height: '127px',
                                        marginBottom: '-20px',
                                      }}
                                    />
                                  </figure>
                                </section>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </DropDown>
                  </li> */}

                  <li className="hide-on-desktop">
                    <a className="menu-link" href="https://dashboard.remoteteam.com/auth/sign-in">
                      <span>Sign in</span>
                    </a>
                  </li>
                </ul>
              </Hamburger>
            </menu>

            <aside className="actions text-center">
              <Link
                className="menu-link hide-on-mobile"
                href="https://dashboard.remoteteam.com/auth/sign-in"
              >
                <span className="mr-1rem" style={{ fontSize: '14px', fontWeight: '700' }}>
                  Sign in
                </span>
              </Link>
              <a className="menu-link cta-button" href="/demo">
                <span className="my-1rem" style={{ fontSize: '14px' }}>
                  BOOK A DEMO
                </span>
              </a>
            </aside>
          </div>
        </div>
      </header>
    </Micro>
  );
};

const DropDown = (props) => {
  const { title, activeDropdown, setDropdown, isMobile, children } = props;
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (title && activeDropdown === title) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [activeDropdown, title]);

  return (
    <div
      onMouseLeave={() => {
        if (!isMobile) {
          setTimeout(setDropdown('close'), 1000);
        }

        document.body.style.overflow = 'auto';
      }}
      onMouseEnter={() => !isMobile && setDropdown(title)}
      role="button"
      tabIndex={0}
    >
      <Link
        className="menu-link"
        onClick={() => {
          if (open) {
            if (isMobile) {
              setDropdown('close');
            }
            document.body.style.overflow = 'auto';
          } else {
            if (isMobile) {
              setDropdown(title);
            }
            document.body.style.overflow = 'scroll';
          }
        }}
      >
        <span>{title}</span>
        {open ? (
          <ChevronUp
            strokeWidth={4}
            size={20}
            style={{ paddingBottom: '-1rem', paddingTop: '0.5rem', display: 'inline' }}
          />
        ) : (
          <ChevronDown
            strokeWidth={4}
            size={20}
            style={{ paddingBottom: '-1rem', paddingTop: '0.5rem', display: 'inline' }}
          />
        )}
      </Link>
      <div style={{ display: activeDropdown === title ? 'block' : 'none' }}>{children}</div>
    </div>
  );
};

const Hamburger = (props) => {
  const { children, hamburgerOpen, setHamburgerStatus } = props;
  const [isMobile, setIsMobile] = useState(false);
  const size = useWindowSize();

  useEffect(() => {
    if (size.width > 1333) {
      setIsMobile(false);
    } else {
      setIsMobile(true);
    }
  }, [size]);

  useEffect(() => {
    if (!isMobile) {
      setHamburgerStatus(true);
    } else {
      setHamburgerStatus(false);
      document.body.style.overflow = 'auto';
    }
  }, [isMobile]);

  return (
    <div role="button" tabIndex={0}>
      {isMobile && (
        <Link
          onClick={() => {
            if (hamburgerOpen) {
              setHamburgerStatus(false);
              document.body.style.overflow = 'auto';
            } else {
              setHamburgerStatus(true);
              document.body.style.overflow = 'hidden';
            }
          }}
        >
          {hamburgerOpen ? (
            <Cross strokeWidth={2} size={28} style={{ margin: '0.5rem' }} />
          ) : (
            <TextAlignJustified
              strokeWidth={2}
              size={28}
              style={{ margin: '0.5rem 0 0.5rem 0.7rem' }}
            />
          )}
        </Link>
      )}
      <div
        style={{
          display: hamburgerOpen ? 'block' : 'none',
          margin: 'none',
          padding: 'none',
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default Menu;
